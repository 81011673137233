<div dropdown class="dropdown">
	<button type="button" [attr.aria-label]="'UI_SortLabel' | translate" dropdownToggle vbUiBtnPrimaryNgx>
		{{ activeLink?.label | translate }}
		<span class="padding-left-5 type-12 glyphicons" [ngClass]="MediaStateService.getIsSortDesc() ? 'chevron-down': 'chevron-up'"></span>
	</button>

	<ul class="dropdown-menu" [ngClass]="styles.dropdownList" *dropdownMenu role="menu" [attr.aria-label]="'SortFilter_Menu' | translate ">
		<li role="menutem" *ngFor="let link of links">
			<button type="button" class="dropdown-item" (click)="setSortField(link)" [ngClass]="MediaStateService.getSortField() === link.sort ? styles.activeAnchor : ''">
				<span [attr.aria-label]="'Selected' | translate " class="glyphicons ok_2" [hidden]="!(MediaStateService.getSortField() === link.sort )"></span>
				{{ link.label | translate }}
			</button>
		</li>
	</ul>
</div>
