<ng-container *ngIf="mediaFeatures && !mediaFeatures.onPrem && !mediaFeatures.webcastSources.webrtcSinglePresenterDisabled">
	<div class="dropdown" dropdown #goLiveFormDropdown="bs-dropdown" (onHidden)="resetForm()" [insideClick]="true" *vbAuthorizationKey="'events.add'" [ngClass]="dropdownCssClass">
		<button dropdownToggle vbUiBtnPrimaryNgx [ignoreBtnPrimaryNgx]="headerType !== 'Classic'" class="dropdown-toggle go-live btn main-nav-btn" type="button" [attr.aria-label]="('Event_GoLive' | translate) + ' - ' + ('UI_Menu' | translate)" [ngClass]="{
				'open': goLiveFormDropdown.isOpen,
				'theme-accent-bg': (goLiveFormDropdown.isOpen || alwaysThemeAccent) && !bgNone,
				'bg-none': bgNone,
				'btn': headerType !== 'Classic'
			}">
			<span [ngClass]="[
					!goLiveFormDropdown.isOpen || !showRemove ? 'vb-icon util-vertical-align-center vb-icon-go-live' : '',
					goLiveFormDropdown.isOpen ? 'theme-accent-txt' : '',
					goLiveFormDropdown.isOpen && showRemove ? 'glyphicons remove_2 rotate-360-once': '',
					iconCssClass
				]">
			</span>
		</button>
		<div *dropdownMenu #formDropDown class="dropdown-menu go-live-menu dropdown-menu-right" role="dialog" [ngClass]="styles.goLiveMenu">
			<go-live-form [mediaFeatures]="mediaFeatures" (close)="goLiveFormDropdown.hide()" [ngClass]="styles.goLive"></go-live-form>
		</div>
	</div>
</ng-container>
