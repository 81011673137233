<form class="sidebar-form contentWrapper vb-scrollbar" #form="ngForm" (submit)="onChangeInternal()" aria-labelledby="filtersForm">
	<ng-container *ngIf="value">
		<collapsable-section-v2 *ngIf="value?.searchTerm && !value.searchTerm.blockUserUpdates" [headerTitle]="'Search' | translate" [startClosed]="true">
			<div class="form-group">
				<label class="control-label" for="searchTerm">{{ 'Search' | translate }}:</label>
				<input type="text" id="searchTerm" name="searchTerm" class="form-control" [(ngModel)]="value.searchTerm.value" (ngModelChange)="onChangeInternal()">
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.title && !value.title.blockUserUpdates" [headerTitle]="'Title' | translate" [startClosed]="true">
			<div class="form-group">
				<label class="control-label" for="titleSearch">{{ 'Media_Search_Filters_TitleSearch' | translate }}:</label>
				<input type="text" id="titleSearch" name="titleSearch" class="form-control" [(ngModel)]="value.title.value" (ngModelChange)="onChangeInternal()">
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.description && !value.description.blockUserUpdates" [headerTitle]="'Description' | translate" [startClosed]="true">
			<div class="form-group">
				<label class="control-label" for="descriptionSearch">{{ 'Media_Search_Filters_DescriptionSearch' | translate }}:</label>
				<input type="text" id="descriptionSearch" name="descriptionSearch" class="form-control" [(ngModel)]="value.description.value" (ngModelChange)="onChangeInternal()">
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.ownerUserId && !value.ownerUserId.blockUserUpdates && !isGuestUser" #ownerSection [headerTitle]="'Media_Videos_Owner' | translate" [startClosed]="true">
			<div class="form-group acl">
				<insight name="owner" (ngModelChange)="onChangeInternal()" [(ngModel)]="value.ownerUserId.value" [insightOptions]="ownerInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow" [hidden]="!ownerSection.expanded">
				</insight>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.whenUploaded && !value.whenUploaded.blockUserUpdates" [headerTitle]="'Media_Search_Filters_UploadDate' | translate" [startClosed]="true">

			<div class="form-group" [ngClass]="{ 'has-error': form?.uploadFromDate?.invalid }">
				<label class="control-label" id="fromDateSearch">{{ 'From' | translate }}</label>
					<div role="group" aria-labelledby="fromDateSearch">
			 		<div class="vb-date-time-input">
						<vb-ui-date-picker flex="75" name="uploadFromDate" [(ngModel)]="value.whenUploaded.value.from" #datePicker="ngModel" (ngModelChange)="onChangeInternal()" [accessibilityLabel]="'DateTimePicker_DateInput' | translate">
						</vb-ui-date-picker>
					</div>
				</div>
				<div class="error-field">
					<label [hidden]="!(form.uploadFromDate?.error.date)">{{ 'ValidDateRequired' | translate }}</label>
				</div>
			</div>

			<div class="form-group" [ngClass]="{ 'has-error': form.uploadToDate?.invalid }">
				<label class="control-label" id="toDateSearch">{{ 'To' | translate }}</label>
					<div role="group" aria-labelledby="toDateSearch">
					<div class="vb-date-time-input">
						<vb-ui-date-picker flex="75" name="uploadToDate" [(ngModel)]="value.whenUploaded.value.to" #datePicker="ngModel" (ngModelChange)="onChangeInternal()" [accessibilityLabel]="'DateTimePicker_DateInput' | translate">
						</vb-ui-date-picker>
					</div>
				</div>

				<div class="error-field">
					<label [hidden]="!(form.uploadToDate?.error.vbMin)">{{ 'Media_Search_Filters_DateRangeError' | translate }}</label>
					<label [hidden]="!(form.uploadToDate?.error.date)">{{ 'ValidDateRequired' | translate }}</label>
				</div>
			</div>

		</collapsable-section-v2>


		<ng-template #accessEntityOptionRow let-item="item" let-insight="insight">
			<access-entity-option-row [item]="item" [insight]="insight"></access-entity-option-row>
		</ng-template>

		<ng-template #accessEntityAssignedRow let-item="item" let-insight="insight">
			<access-entity-assigned-row [item]="item" [insight]="insight"></access-entity-assigned-row>
		</ng-template>

		<collapsable-section-v2 *ngIf="value?.uploaderUserId && !value.uploaderUserId.blockUserUpdates && !isGuestUser" #uploaderSection [headerTitle]="'Media_Videos_Uploader' | translate" [startClosed]="true">
			<div class="form-group acl">
				<insight name="uploader" (ngModelChange)="onChangeInternal()" [(ngModel)]="value.uploaderUserId.value" [insightOptions]="uploaderInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow" [hidden]="!uploaderSection.expanded">
				</insight>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.userTags && !value.userTags.blockUserUpdates && !isGuestUser" #userTagsSection [headerTitle]="'InThisVideo' | translate" [startClosed]="true">
			<div class="form-group acl">
				<insight name="userTags" (ngModelChange)="onChangeInternal()" [(ngModel)]="value.userTags.value" [insightOptions]="userTagsInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow" [hidden]="!userTagsSection.expanded">
				</insight>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.isActive && !value.isActive.blockUserUpdates" [headerTitle]="'Status' | translate" [startClosed]="true">
			<div class="form-group">
				<select class="form-select" [attr.aria-label]="'Status' | translate " [(ngModel)]="value.isActive.value" name="status" (ngModelChange)="onChangeInternal()">
					<option [ngValue]="true">
						{{'Active' | translate}}
					</option>
					<option [ngValue]="false">
						{{'Inactive' | translate}}
					</option>
				</select>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.isLive && !value.isLive.blockUserUpdates" [headerTitle]="'VideoType' | translate" [startClosed]="true">
			<div class="form-group">
				<select class="form-select" [attr.aria-label]="'VideoType' | translate " name="videoType" [(ngModel)]="value.isLive.value" (ngModelChange)="onChangeInternal()">
					<option [ngValue]="false">
						{{'VOD' | translate}}
					</option>
					<option [ngValue]="true">
						{{'Live' | translate}}
					</option>
				</select>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.is360 && !value.is360.blockUserUpdates" [headerTitle]="'Filter360Video' | translate" [startClosed]="true">
			<div class="form-group">
				<select class="form-select" [attr.aria-label]="'Filter360Video' | translate " name="filter360Video" [(ngModel)]="value.is360.value" (ngModelChange)="onChangeInternal()">
					<option [ngValue]="true">
						{{'FilterOptionIs360Video' | translate}}
					</option>
					<option [ngValue]="false">
						{{'FilterOptionNot360Video' | translate}}
					</option>
				</select>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.tags && !value.tags.blockUserUpdates" #tagsSection [headerTitle]="'Tags' | translate" [startClosed]="true">
			<div id="tags" uiControl>
				<vb-tags-input *ngIf="tagsSection.expanded" name="tags" [placeholder]="'TagsInputPlaceholder' | translate" [(ngModel)]="value.tags.value" (ngModelChange)="onChangeInternal()">
				</vb-tags-input>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.categoryIds && !value.categoryIds.blockUserUpdates && isCategoryFilterEnabled" #categoriesSection [headerTitle]="'Media_Search_Filters_CategoryFilter' | translate" [startClosed]="true">
			<div id="categories" uiControl>
				<vb-categories-tags-input *ngIf="categoriesSection.expanded" [placeholder]="'Media_Videos_Settings_CategoriesInputPlaceholder' | translate" name="categories" [(ngModel)]="value.categoryIds.value" [whitelist]="categories" (ngModelChange)="onChangeInternal()">
				</vb-categories-tags-input>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.teamIds && !value.teamIds.blockUserUpdates && teams?.length > 1" #teamsSection [headerTitle]="'Title_Teams' | translate" [startClosed]="true">
			<div class="form group">
				<vb-teams-tags-input *ngIf="teamsSection.expanded" [placeholder]="'Media_Videos_Settings_TeamsInputPlaceholder' | translate" name="teamsTags" [whitelist]="teamsWhitelist" [(ngModel)]="value.teamIds.value" (ngModelChange)="onTeamTagsChanged($event)">
				</vb-teams-tags-input>
			</div>
		</collapsable-section-v2>

		<collapsable-section-v2 *ngIf="value?.unlisted && !value.unlisted.blockUserUpdates && isUnlistVideoEnabled && !isGuestUser" [headerTitle]="'Unlisted' | translate" [startClosed]="true">
			<div class="form-group">
				<select class="form-select" name="unlistedVideo" [attr.aria-label]="'Unlisted' | translate" [(ngModel)]="value.unlisted.value" (ngModelChange)="onChangeInternal()">
					<option [ngValue]="true">
						{{'FilterOptionUnlistedVideo' | translate}}
					</option>
					<option [ngValue]="false">
						{{'FilterOptionNotUnlistedVideo' | translate}}
					</option>
				</select>
			</div>
		</collapsable-section-v2>
	</ng-container>
	<button class="hidden-submit" type="submit" aria-hidden="true" tabindex="-1"></button>
	<div class="clear-btn-wrapper" *ngIf="isClearVisible">
		<button type="button" (click)="clear()" class="btn btn-primary">{{ 'Media_Search_Filters_ClearAll' | translate }}</button>
	</div>
</form>
