import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Subscription } from 'rxjs';
import { debounce } from 'underscore';

import { ICategorySettings } from 'rev-shared/ui/categorySettings/ICategorySettings';

import { ISearchFilters, SearchFilterStateService, getFilterValues } from './SearchFilterState.Service';
import { TABLE_DATA_MODE } from './tableLayout/Contract';

import './SearchFiltersSidebar.less';


@Component({
	selector: 'search-filters-sidebar',
	templateUrl: './SearchFiltersSidebar.Component.html',
	host: {
		class: 'container-fluid filters-sidebar vb-scrollbar overflow-scroll'
	}
})

export class SearchFiltersSidebarComponent implements OnDestroy, OnInit {
	@Input() public categories: ICategorySettings[];
	@Input() public isGuestUser: boolean;
	@Input() public tableDataMode: TABLE_DATA_MODE;
	@Input() public teams: any[];

	private readonly AUTO_SUBMIT_TIMER_MS: number = 2000;
	private searchFilterStateChangeSub: Subscription;

	public filters: ISearchFilters;
	public onChange = debounce(() => this.submit(), this.AUTO_SUBMIT_TIMER_MS);

	constructor(
		private SearchFilterState: SearchFilterStateService,
		private $state: StateService
	) {}

	public ngOnInit(): void {
		this.filters = this.SearchFilterState.clone();
		this.searchFilterStateChangeSub = this.SearchFilterState.change$.subscribe(() =>
			this.filters = this.SearchFilterState.clone());
	}

	public ngOnDestroy(): void {
		this.searchFilterStateChangeSub?.unsubscribe();
	}

	private submit(): void {
		const filterValues = getFilterValues(this.filters, true);
		this.SearchFilterState.go(filterValues, this.$state);
	}
}
