<section class="dropdown main-nav-links box-block" dropdown>
		<button type="button" class="text-color-inherit dropdown-toggle" aria-controls="media-dropdown" [attr.aria-label]="('Media' | translate) + '-' + ('UI_Menu' | translate)" [ngClass]="{'theme-header-txt util-no-underline util-uppercase': isTwoTierTheme}" dropdownToggle (click)="onToggleClick()">
			<span>{{ 'Media' | translate }}</span>
			<span vbCaretDirective [ngClass]="{'theme-header-font' : isTwoTierTheme}"></span>
		</button>
		<div class="pip theme-accent-bg" [hidden]="isTwoTierTheme">
		</div>
		<media-menu *dropdownMenu id="media-dropdown" class="dropdown-menu" role="menu">
		</media-menu>
</section>
