<div display="flex" class="height-full" [ngClass]="styles.categoryAndVideo">
	<a class="d-none d-lg-inline" [ngClass]="styles.thumbnailCell" uiSref="portal.media.category-detail" [uiParams]="{ categoryId: category.id }" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0' : category.name }">

		<div *ngIf="category.thumbnailImageUri; else noImage" class="fixed-ratio-wrapper">
			<div class="fixed-ratio">
				<img [src]="category.thumbnailImageUri" loading="lazy"/>
			</div>
		</div>
		<ng-template #noImage>
			<span class="vb-icon vb-icon-folder-play"></span>
		</ng-template>
	</a>
	<a [ngClass]="styles.name" uiSref="portal.media.category-detail" [uiParams]="{ categoryId: category.id }" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0' : category.name }">
		<div>
			<span class="text-ellipsis" [ngClass]="styles.title" [title]="category.name">
				{{ category.name }}
			</span>
			<span [ngClass]="styles.subTitle">
				<span *ngIf="category.restricted" class="glyphicons lock margin-right-5"></span>

				{{ (category.videoCount === 1
					? 'Media_OneVideo'
					: 'Media_NumVideos') | translate : { '0' : (category.videoCount || 0) } }}
			</span>
		</div>
	</a>

	<div [ngClass]="styles.edit">
		<a *ngIf="category.canEdit" uiSref="portal.edit-category" [uiParams]="{ categoryId: category.id }" vbUiBtnPrimaryNgx>
			{{ 'Edit' | translate }}
		</a>
	</div>
</div>
