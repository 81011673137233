<div class="media-body-wrapper" [ngSwitch]="notification.notificationType" [ngClass]="[
		(notification.priority === 'High' || notification.priority === 'Critical') ? 'notification-alert' : '',
		notification.priority === 'Low' ? 'notification-complete' : '',
		notification.markedAsRead ? 'is-read' : ''
	]">

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoUploadingFailed'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_UnableNotCompleted' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="['VideoLinkCreated', 'VideoPresentationProfileCreated'].includes(notification.notificationType) ? notification.notificationType : !notification.notificationType" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons upload"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_UploadCompleted' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoTranscoded'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_UploadCompleted' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoIsReady'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_VideoIsReady' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoZipIsReadyForDownload'" target="_blank" uiSref="download-details" [uiParams]="{id: notification.content.videoId, downloadUrl: notification.content.downloadUrl}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_VideoZipIsReadyForDownload' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>
			<p class="notification-msg">{{ 'Notifications_VideoZipValidDays' | translate : { '0': notification.content.validDays } }}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoInstanceStoringFailed'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_UnableToProcessVideo' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoProcessingFailed'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_UnableToProcessVideo' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ProcessingWebcastPresentationFailed'" uiSref="portal.webcast" [uiParams]="{webcastId: notification.content.webcastId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>

		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Event_UnableToProcessPresentation' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'UploadingWebcastPresentationFailed'" uiSref="portal.webcast" [uiParams]="{webcastId: notification.content.webcastId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>

		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Event_PresentationUploadFailed' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'StopRecordingFailed'" class="box-block util-no-underline" uiSref="portal.webcast" [uiParams]="{webcastId: notification.content.webcastId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>

		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Event_RecordingFailedNotification' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'UploadVideoCategoriesIgnored'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>

		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_CategoriesIgnored' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}} - {{notification.content.categories}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>
	<a *ngSwitchCase="'UploadVideoAccessControlEntitiesIgnored'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>

		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_AccessControlEntitiesIgnored' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}} - {{notification.content.accessControlEntities}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'DownloadingVideoFailed'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Uploads_Import_DownloadFailed' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<div *ngSwitchCase="'SparkCategorySubscriptionFailure'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Media_Videos_Spark_NotificationTitle' | translate }}</h4>

			<p class="notification-msg">
				{{ 'Media_Videos_Spark_NotificationMessage' | translate }}<br>
				{{ 'Media_Videos_Spark_SparkRoom' | translate : { '0': notification.content.roomTitle } }}<br>
				{{ 'Media_Videos_Spark_Video' | translate : { '0': notification.content.videoTitle } }}
			</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'DeleteVideoFromVoiceBaseFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Media_Videos_Transcription_DeleteVideoFailed' | translate }}</h4>

			<p class="notification-msg">
				{{ 'Media_Videos_Transcription_Video' | translate : { '0': notification.content.videoTitle } }}
			</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'VideosUpdated'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Media_BulkEdit_Notification_Title' | translate }}</h4>

			<p class="notification-msg">{{ 'Media_BulkEdit_Notification_Message' | translate }}</p>

			<p class="notification-msg" [hidden]="!(notification.content.videoCount)">{{ 'Media_BulkEdit_Notification_VideosUpdated' | translate : { '0': notification.content.videoCount } }}</p>

			<p class="notification-msg" [hidden]="!(notification.content.videoCountOnLegalHold)">{{ 'Media_BulkEdit_Notification_VideosOnLegalHold' | translate : { '0': notification.content.videoCountOnLegalHold } }}</p>

			<p class="notification-msg" [hidden]="!(notification.content.videoCountNotActivated)">{{ 'Media_BulkEdit_Notification_VideosActivateFailed' | translate : { '0': notification.content.videoCountNotActivated } }}</p>

			<p class="notification-msg" [hidden]="!(notification.content.videoCountUnauthorizedEdit)">{{ 'Media_BulkEdit_Notification_VideosUnauthorizedEdit' | translate : { '0': notification.content.videoCountUnauthorizedEdit } }}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'VideosDeleted'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Media_BulkEdit_Notification_Title' | translate }}</h4>

			<p class="notification-msg">{{ 'Media_BulkEdit_Notification_Message' | translate }}</p>

			<p class="notification-msg">{{ 'Media_BulkEdit_Notification_VideosDeleted' | translate : { '0': notification.content.videoCount } }}</p>

			<p class="notification-msg" [hidden]="!notification.content.failedCount">{{ 'Media_BulkEdit_Notification_VideosFailedToDelete' | translate : { '0': notification.content.failedCount } }}</p>

			<p class="notification-msg" [hidden]="!notification.content.legalHoldVideosCount">{{ 'Media_BulkEdit_Notification_FailedDelete_VideosOnLegalHold' | translate : { '0': notification.content.legalHoldVideosCount } }}</p>

			<p class="notification-msg" [hidden]="!notification.content.recordingVideosCount">{{ 'Media_BulkEdit_Notification_FailedDelete_VideosCurrentlyRecording' | translate : { '0': notification.content.recordingVideosCount } }}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<a *ngSwitchCase="'VideoReported'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Media_Videos_Playback_ReportNotification_Header' | translate }}</h4>

			<p class="notification-msg">
				{{ 'Media_Videos_Playback_ReportNotification_Message' | translate : { '0': notification.content.reporter, '1':  notification.content.videoTitle, '2': notification.content.uploader } }}
			</p><p>
			</p><p class="notification-msg">
				 {{notification.content.reason}}
			</p>
			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<div *ngSwitchCase="'UsersUploadFinalized'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'UsersUploadCompleted' | translate }}</h4>

			<p class="notification-msg">{{ 'UsersUploadUsersCreated' | translate : { '0': notification.content.usersCreated } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersDeleted' | translate : { '0': notification.content.usersDeleted  } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersUpdated' | translate : { '0': notification.content.usersUpdated } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersIgnored' | translate : { '0': notification.content.usersIgnored } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersError' | translate : { '0': notification.content.usersFailed } }}</p><p>

			</p><p class="notification-msg">{{ 'UsersUploadGroupsCreated' | translate : { '0': notification.content.groupsCreated } }}</p><p>

			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'ProcessingUsersUploadFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'UsersUploadProcessingFailed' | translate }}</h4>

			<p class="notification-msg">{{ 'UsersUploadUsersCreated' | translate : { '0': notification.content.usersCreated } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersDeleted' | translate : { '0': notification.content.usersDeleted } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersUpdated' | translate : { '0': notification.content.usersUpdated } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersIgnored' | translate : { '0': notification.content.usersIgnored } }}</p><p>
			</p><p class="notification-msg">{{ 'UsersUploadUsersError' | translate : { '0': notification.content.usersFailed } }}</p><p>

			</p><p class="notification-msg">{{ 'UsersUploadGroupsCreated' | translate : { '0': notification.content.groupsCreated } }}</p><p>

			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'UsersUploadTimedout'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'UsersUploadTimedout' | translate }}</h4>

			<p class="notification-msg">
				{{ 'UsersUploadCreatedUsers' | translate : { '0': notification.content.usersCreated } }}
			</p><p>
			</p><p class="notification-msg">
				{{ 'UsersUploadCreatedGroups' | translate : { '0': notification.content.groupsCreated } }}
			</p><p>
			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'DmeDownloadUpgradeFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Device_Upgrade_Notification_DownloadFailed' | translate }}</h4>

			<p class="notification-msg">
				{{ 'Device_Upgrade_Notification_DeviceName' | translate : { '0': notification.content.deviceName } }}
			</p><p>
			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'DmeInstallUpgradeFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Device_Upgrade_Notification_UpdateFailed' | translate }}</h4>

			<p class="notification-msg">
				{{ 'Device_Upgrade_Notification_DeviceName' | translate : { '0': notification.content.deviceName } }}
			</p><p>
			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'DmeInstallUpgradeCompleted'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Device_Upgrade_Notification_Completed' | translate }}</h4>

			<p class="notification-msg">
				{{ 'Device_Upgrade_Notification_DeviceName' | translate : { '0': notification.content.deviceName } }}
			</p><p>
			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'DmeUpgradeChecksFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Device_Upgrade_Checks_Failed' | translate }}</h4>

			<p class="notification-msg">
				{{ 'Device_Upgrade_Checks_Failed_Details' | translate }}
			</p><p>

			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'MasterKeyRotationFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_Keys_RotationFailureHeader' | translate }}</h4>

			<p class="notification-msg">
				<span [hidden]="!(notification.content.rotationFailureReason === 'NewKeyAccessLost')">{{ 'Notifications_Keys_RotationFailureBodyCurrent' | translate }}</span>
				<span [hidden]="!(notification.content.rotationFailureReason !== 'NewKeyAccessLost')">{{ 'Notifications_Keys_RotationFailureBodyPrevious' | translate }}</span>
			</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'EncryptionMasterKeyAccessLost'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_Keys_AccessLostHeader' | translate }}</h4>

			<p class="notification-msg">
				<span>{{ 'Notifications_Keys_AccessLostBody' | translate }}</span>
			</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>
	<a *ngSwitchCase="'UserListCsvDownloadComplete'" class="box-block util-no-underline" target="_blank" uiSref="download-details" [uiParams]="{id: notification.content.fileId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_UsersDownload' | translate }}</h4>

			<p class="notification-msg">{{notification.content.fileName}}</p>

			<p class="“notification-time-stamp”">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'VideoInventoryCsvDownloadComplete'" class="box-block util-no-underline" target="_blank" uiSref="download-details" [uiParams]="{id: notification.content.fileId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_VideoInventoryDownload' | translate }}</h4>

			<p class="notification-msg">{{notification.content.fileName}}</p>

			<p class="“notification-time-stamp”">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'AccountEventAnalyticsDownloadComplete'" class="box-block util-no-underline" target="_blank" uiSref="download-details" [uiParams]="{id: notification.content.fileId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_AccountEventsDownload' | translate }}</h4>

			<p class="notification-msg">{{notification.content.fileName}}</p>

			<p class="“notification-time-stamp”">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>


	<div *ngSwitchCase="'RefreshWebexAdminTokenFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'WebexMeetingIntegrationUpdateMessage' | translate }}</h4>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<div *ngSwitchCase="'TagUsersJobUpdatedFinished'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'FacialRecognition_TaggingSuccessful' | translate }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<a *ngSwitchCase="'TagUsersJobUpdatedFailed'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'FacialRecognition_TaggingFailed' | translate }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<div *ngSwitchCase="'AutoIngestDisabled'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Webex_AutoIngestDisabled' | translate }}</h4>
			<h4 class="media-heading">{{ 'Webex_AutoIngestDisabledMessage' | translate }}</h4>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</div>

	<a *ngSwitchCase="'VideoTranscriptionJobFinished'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Video_Subtitles_Success' | translate }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-msg">
				{{ 'Video_Subtitles_Success_Count' | translate : { '0': notification.content.successCount } }}
			</p><p>

			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'VideoMetadataGenerationJobFinished'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Video_Metadata_Generation_Success' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'VideoMetadataGenerationJobFailed'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Video_Metadata_Generation_Failed' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'TranscriptionFileGenerationFailed'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Video_Subtitles_Failed' | translate }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'VideoTranslationJobUpdated'" class="box-block util-no-underline" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading" [hidden]="!(notification.content.isCompleted)">{{ 'Video_Subtitles_Success' | translate }}</h4>
			<h4 class="media-heading" [hidden]="notification.content.isCompleted">{{ 'Video_Subtitles_Failed' | translate }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-msg" [hidden]="!(notification.content.successCount &gt; 0)">
				{{ 'Video_Subtitles_Success_Count' | translate : { '0': notification.content.successCount } }}
			</p><p>

			</p><p class="notification-msg" [hidden]="!(notification.content.failureCount &gt; 0)">
				{{ 'Video_Subtitles_Failed_Count' | translate : { '0': notification.content.failedCount } }}
			</p><p>

			</p><p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'DmeDeviceLogsRequestActionAdded'" uiSref="portal.admin" [uiParams]="{deviceId: notification.content.deviceId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'DmeLogsRequested' | translate }}</h4>

			<p class="notification-msg">{{notification.content.deviceId}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'DmeDeviceRequestLogsActionResultRecorded'" uiSref="portal.admin" [uiParams]="{deviceId: notification.content.deviceId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'DmeLogsProcessed' | translate }}</h4>

			<p class="notification-msg">{{notification.content.deviceId}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'CannotMigrateDmeCdnToAws'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>

		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Admin_Devices_DmeAwsPushMigrationFailed' | translate : { '0': notification.content.accountName } }}</h4>
			<ul><li class="notification-details-list" *ngFor="let issue of notification.content.migrationIssues">{{ issue.collection }}: {{ issue.name }} ({{ issue.issue }})</li></ul>
			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'CannotRollbackDmeCdnToAkamai'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>

		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Admin_Devices_DmeAwsPushRollbackFailed' | translate : { '0': notification.content.accountName } }}</h4>
			<ul><li class="notification-details-list" *ngFor="let issue of notification.content.rollbackIssues">{{ issue.collection }}: {{ issue.name }}/{{ issue.stream }}</li></ul>

			<label class="text-white">{{ 'Admin_Devices_DmeAwsPushRollbackFailed_NoAkamai' | translate }}</label>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ProcessingCustomDictionaryFailed'" uiSref="portal.admin.media-settings.integrations">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Admin_MediaSettings_CustomDictionaryFailed' | translate }}</h4>

			<p class="notification-msg">{{ 'Language_' + notification.content.languageCode | translate }}</p>

			<p class="notification-msg">{{ notification.content.errorMessage }}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoOwnershipChanged'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Media_Videos_VideoOwnershipChanged' | translate }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideosOwnershipChanged'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Media_Videos_VideosOwnershipChanged' | translate : { '0': notification.content.count } }}</h4>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ProcessingRegistrationsUploadFinished'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Email_WebcastPreRegistrationImport_Line1' | translate }} : {{ notification.content.title }}</h4>

			<p class="notification-msg">{{ 'Email_WebcastPreRegistrationImport_Line2' | translate : { '0': notification.content.totalInserted } }}</p>

			<p class="notification-msg">{{ 'Email_WebcastPreRegistrationImport_Line3' | translate : { '0': notification.content.totalUpdated } }}</p>

			<p class="notification-msg">{{ 'Email_WebcastPreRegistrationImport_Line4' | translate : { '0': notification.content.totalUnChanged } }}</p>

			<p class="notification-msg">{{ 'Email_WebcastPreRegistrationImport_Line5' | translate : { '0': notification.content.totalFailed } }}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ProcessingRegistrationsUploadFailed'">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Email_WebcastPreRegistrationImport_Failed' | translate }} : {{ notification.content.title }}</h4>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a *ngSwitchCase="'WebcastPreRegisteredGuestsDownloadComplete'" class="box-block util-no-underline" target="_blank" uiSref="download-details" [uiParams]="{id: notification.content.fileId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_WebcastRegistrationsDownload' | translate }}</h4>

			<p class="notification-msg">{{notification.content.fileName}}</p>

			<p class="“notification-time-stamp”">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'LdapSyncFailed'" uiSref="portal.admin.devices" [uiParams]="{accountId: notification.content.accountId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'LdapSyncFailed' | translate }}</h4>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'SubscriptionNotificationSent'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Video_SubscriptionNotificationSent_Subject_OneOrMany' | translate : {'0': notification.content.subscriptionName } }}</h4>

			<p class="notification-msg">{{notification.content.title}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'SubscriptionsNotificationSent'" uiSref="portal.media.my-subscriptions">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Video_SubscriptionsNotificationSent' | translate : {'0': notification.content.count } }}</h4>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'RepliedToVideoComment'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Email_ReplyToVideoComment_Line1' | translate }}</h4>

			<p class="notification-msg">{{ 'Email_ReplyToVideoComment_Subject' | translate : {'0' : notification.content.repliedBy} }}</p>
			<p class="notification-msg">{{notification.content.commentText}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoSubmittedForApproval'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_VideoSubmittedForApproval' | translate : {'0': notification.content.submitter } }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ContentReviewsFailed'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_VideoApprovalRejection_ContentReviewsFailed' | translate : {'0': notification.content.submitter } }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'InsufficientRevAICreditsForContentAnalysis'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_VideoApprovalRejection_InsufficientLicense' | translate : {'0': notification.content.submitter } }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoRejectedManually'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons circle_exclamation_mark"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_VideoApprovalRejection_Manual' | translate : {'0': notification.content.videoTitle, '1': notification.content.stepName } }}</h4>

			<p class="notification-msg">{{notification.content.reason}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'VideoApproved'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_VideoApproved' | translate : {'0': notification.content.videoTitle } }}</h4>

			<p class="notification-msg">{{ "Notifications_VideoApproved_Reason" | translate }} &nbsp;
				{{ notification.content.isAutoApproved === true ? ((notification.content.isSkipped ? "ComplianceAlerts_AutoApproved_NoTranscript" : "ComplianceAlerts_AutoApproved") | translate) : notification.content.reason }}
			</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ComplianceAlertsReplyAdded'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_ComplianceAlerts_Reply' | translate }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ContentReviewsCommentReplied'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">
				{{ notification.content.isVideoOwner
					? ("Notifications_ComplianceComments_Owner_Reply" | translate : {'0': notification.content.fullName })
					: ("Notifications_ComplianceComments_Reply" | translate : {'0': notification.content.fullName })
				}}
			</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

	<a class="box-block util-no-underline" *ngSwitchCase="'ContentReviewsCommentAdded'" uiSref="portal.video" [uiParams]="{videoId: notification.content.videoId}">
		<div class="notification-tab">
			<div class="ff-wrap-fix">
				<span class="glyphicons ok_2"></span>
			</div>
		</div>
		<div class="media-body-fill">
			<h4 class="media-heading">{{ 'Notifications_ComplianceComments' | translate: {'0': notification.content.fullName } }}</h4>

			<p class="notification-msg">{{notification.content.videoTitle}}</p>

			<p class="notification-time-stamp">{{notification.notificationDate| vbDateTimeMedium}}</p>
		</div>
	</a>

</div>