import { Injectable } from '@angular/core';

import { formatIsoDate } from 'rev-shared/date/DateFormatters';
import { MetadataService } from 'rev-shared/metadata/Metadata.Service';

export enum BulkEditOperator {
	append = 'Append',
	remove = 'Remove',
	replace = 'Replace'
}

export interface IBulkEditOperation {
	fieldName: string;
	operator: BulkEditOperator;
	value: any;
}

export interface IBulkEditFormGroup {
	operation?: BulkEditOperator;
	value: any;
	id?: string;
}

export interface IBulkEditForm {
	customFieldValues: IBulkEditFormGroup[];
	owner: IBulkEditFormGroup;
	publishDate: IBulkEditFormGroup;
	expiration: IBulkEditFormGroup;
	isActive: IBulkEditFormGroup;
	enableAutoShowChapterImages: IBulkEditFormGroup;
	downloadingEnabled: IBulkEditFormGroup;
	commentsEnabled: IBulkEditFormGroup;
	ratingsEnabled: IBulkEditFormGroup;
	accessControl: IBulkEditFormGroup;
	unlisted: IBulkEditFormGroup;
	tags: IBulkEditFormGroup;
	categories: IBulkEditFormGroup;
	selectedTeams: IBulkEditFormGroup;
	accessControlEntities: IBulkEditFormGroup;
	userTags: IBulkEditFormGroup;
}

interface IArrayOperationConfig {
	mapValue: (value: any) => any;
	name: string;
	operationName?: string;
}

@Injectable({
	providedIn: 'root'
})
export class BulkEditFormStateService {
	public fields: IBulkEditForm;

	constructor(
		private MetadataService: MetadataService
	) {
	}

	public get operations() {
		return BulkEditOperator;
	}

	public init(): void {
		this.fields = getDefaultFields() as IBulkEditForm;
		this.fields.customFieldValues = this.MetadataService.getUserContextVideoFields() as any[];
	}

	public clear(): void {
		this.fields = null;
	}

	//todo: implement faster version that checks for at least one operation
	public getBulkOperations(): IBulkEditOperation[] {
		if (!this.fields) {
			return [];
		}

		return [
			...this.getValueTypeOperations(),
			this.getPublishDateOperation(),
			this.getExpirationOperation(),
			...this.getArrayOperations(),
			...this.getCustomFieldOperations(),
			this.getOwnerOperation()
		].filter(Boolean);
	}

	private getArrayOperation(cfg: IArrayOperationConfig): IBulkEditOperation {
		const field = this.fields[cfg.name];

		if (field && field.operation && (field.value || []).length) {
			return {
				operator: field.operation,
				fieldName: cfg.operationName || cfg.name,
				value: (field.value || []).map(cfg.mapValue)
			};
		}
	}

	private getArrayOperations(): IBulkEditOperation[] {
		return [{
			name: 'tags',
			mapValue: val => val
		}, {
			name: 'categories',
			operationName: 'categoryIds',
			mapValue: c => c.categoryId
		}, {
			name: 'accessControlEntities',
			mapValue: e => ({
				id: e.id,
				type: e.type,
				canEdit: e.canEdit
			})
		}, {
			name: 'userTags',
			mapValue: e => e.id
		}].map((cfg: IArrayOperationConfig) => this.getArrayOperation(cfg));
	}

	private getCustomFieldOperations(): IBulkEditOperation[] {
		return this.fields.customFieldValues.map(field => {
			if (field.operation) {
				return {
					operator: field.operation,
					fieldName: 'customFieldValues/' + field.id,
					value: field.operation !== BulkEditOperator.remove ? field.value : null
				};
			}
		});
	}

	private getExpirationOperation(): IBulkEditOperation {
		const field = this.fields.expiration;

		if (field?.operation) {
			return {
				operator: field.operation,
				fieldName: 'expiration',
				value: field.operation !== BulkEditOperator.remove ? field.value : null
			};
		}

		return;
	}

	private getOwnerOperation(): IBulkEditOperation {
		const field = this.fields.owner.value;
		return field?.id ? {
			operator: BulkEditOperator.replace,
			fieldName: 'ownerUserId',
			value: field.operation !== BulkEditOperator.remove ? field.id : null
		} : null;
	}

	private getPublishDateOperation(): IBulkEditOperation {
		const field = this.fields.publishDate;
		if (field?.operation) {
			return {
				operator: field.operation,
				fieldName: 'publishDate',
				value: field.operation !== BulkEditOperator.remove ? formatIsoDate(field.value) : null
			};
		}
	}

	private getValueTypeOperations(): IBulkEditOperation[] {
		return [
			'isActive',
			'downloadingEnabled',
			'commentsEnabled',
			'ratingsEnabled',
			'accessControl',
			'unlisted',
			'enableAutoShowChapterImages'
		].map(name => {
			const field = this.fields[name];

			if (field && field.value != null) {
				return {
					operator: BulkEditOperator.replace,
					fieldName: name,
					value: field.value
				};
			}
		});
	}
}

function getDefaultFields(): Partial<IBulkEditForm> {
	return {
		categories: { value: [] },
		expiration: { value: undefined },
		tags: { value: [] },
		accessControl: { value: undefined },
		accessControlEntities: { value: [] },
		isActive: { value: undefined },
		publishDate: { value: undefined },
		userTags: { value: undefined },
		downloadingEnabled: { value: undefined },
		commentsEnabled: { value: undefined },
		ratingsEnabled: { value: undefined },
		unlisted: { value: undefined },
		owner: { value: undefined },
		enableAutoShowChapterImages: { value: undefined }
	};
}
